body {
  margin: 0;
  font-family: "ArialRoundedMT", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-family: "ArialRoundedMT", monospace;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.white-content {
  background: #e4e4e4;
}

@font-face {
  font-family: 'ArialRoundedMT';
  font-style: normal;
  font-weight: normal;
  src: local('ArialRoundedMT_Regular'), url(./assets/fonts/ArialRoundedMT_Regular.ttf) format('truetype');
}
