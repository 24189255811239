@import '~antd/dist/antd.css';
@import '~react-big-calendar/lib/css/react-big-calendar.css';

:root {
  --white: #fff;
  --black: #444;
  --text-primary: #222222;
  --text-secondary: #ffffff;
  --accent-color: #388e84;
  --accent-color-hover: rgba(56, 142, 132, 0.8);
  --accent-color-active: #2d746a;
  --accent-color-background: rgba(83, 206, 194, 0.2);
  --accent-color-box-shadow: rgba(45, 116, 106, 0.2);
  --secondary-accent-color: #62528E;
  --secondary-accent-color-hover: rgba(98, 82, 142, 0.8);
  --background-primary: #ffffff;;
  --background-secondary: #edf4f3;
  --background-menu: #f6f6f6;
}

.App {
  text-align: center;
}

a {
  color: var(--accent-color);
}

a:hover {
  color: var(--accent-color-hover);
}

a:active {
  color: var(--accent-color-active);
}

a.ant-typography, .ant-typography a {
  color: var(--accent-color);
}

a.ant-typography:active, .ant-typography a:active {
  color: var(--accent-color-active);
}

a.ant-typography:focus, .ant-typography a:focus, a.ant-typography:hover, .ant-typography a:hover {
  color: var(--accent-color-hover);
}

.ant-menu {
  background-color: var(--background-menu) !important;
}

.ant-layout-header {
  line-height: 74px;
  height: 74px;
  padding: 0 8px;
  color: var(--text-primary);
  background-color: var(--background-menu);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

@media only screen and (max-device-width : 650px) {
  .ant-layout-header {
    line-height: 54px;
    height: 54px;
  }
}

.ant-btn {
  border-radius: 4px;
}

.ant-btn:hover, .ant-btn:focus, .ant-btn:active {
  color: var(--accent-color);
  border-color: var(--accent-color);
}

.ant-btn-primary {
  color: var(--text-secondary);
  border: transparent;
  background: var(--accent-color);
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: var(--text-secondary);
  background: var(--accent-color-hover);
}

.ant-btn-link {
  color: var(--accent-color);
}

.ant-btn-link:hover,
.ant-btn-link:focus,
.ant-btn-link:active {
  color: var(--accent-color-hover);
  border-color: transparent;
}

.ant-input:focus, .ant-input-focused {
  border-color: var(--accent-color);
  box-shadow: 0 0 0 2px var(--accent-color-box-shadow);
}

.ant-input:hover {
  border-color: var(--accent-color);
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: var(--accent-color);
}

.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
  border-color: var(--accent-color);
  box-shadow: 0 0 0 2px var(--accent-color-box-shadow);
}

.ant-input-number:hover {
  border-color: var(--accent-color);
}

.ant-input-number:focus, .ant-input-number-focused {
  border-color: var(--accent-color);
  box-shadow: 0 0 0 2px var(--accent-color-box-shadow);
}

.ant-input-search .ant-input:hover, .ant-input-search .ant-input:focus {
  border-color: var(--accent-color);
}

.ant-layout-content {
  background-color: var(--background-primary);
  padding: 20px 50px;
}

@media only screen and (max-device-width : 650px) {
  .ant-layout-content {
    padding: 20px 30px
  }
}

.ant-layout-sider-children {
  color: var(--text-primary);
  background: var(--background-menu);
}

.ant-list-item-meta-title > a:hover {
  color: var(--accent-color);
}

.ant-menu-horizontal {
  border-bottom: transparent;
}

.ant-menu-horizontal > .ant-menu-item:hover {
  background-color: transparent;
}

.ant-menu-horizontal > .ant-menu-item:hover > span {
  color: var(--accent-color);
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 2px solid var(--accent-color);
}

.ant-menu-inline > .ant-menu-item > span {
  font-size: 14px;
}

.ant-menu-item {
  height: 54px;
}

.ant-menu-item > span {
  line-height: normal;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  word-wrap: break-word;
  color: var(--text-primary);
  font-weight: 500;
}

.ant-menu-item:active, .ant-menu-submenu-title:active {
  background: var(--accent-color-background);
}

.ant-menu-item-selected > span {
  color: var(--accent-color);
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: var(--accent-color-background);
}

.ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: transparent;

}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid var(--accent-color);
}

.ant-page-header {
  margin-bottom: 16px;
}

.ant-page-header-heading-title {
  font-size: 28px;
}

.ant-page-header-back-button:focus, .ant-page-header-back-button:hover {
  color: var(--accent-color);
}

.ant-page-header-back-button:active {
  color: var(--accent-color-active);
}

.ant-pagination-item:hover {
  border-color: var(--secondary-accent-color);
}
.ant-pagination-item:hover a {
   color: var(--secondary-accent-color);
}

.ant-pagination-item-active {
  border-color: var(--secondary-accent-color);
}

.ant-pagination-item-active:hover {
  border-color: var(--secondary-accent-color-hover);
}

.ant-pagination-item-active a {
  color: var(--secondary-accent-color);
}

.ant-pagination-item-active:hover a {
  color: var(--secondary-accent-color-hover);
}

.ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
  color: var(--secondary-accent-color);
  border-color: var(--secondary-accent-color);
}

.ant-picker-focused {
  border-color: var(--accent-color);
  box-shadow: 0 0 0 2px var(--accent-color-box-shadow);
}

.ant-picker:hover, .ant-picker-focused {
  border-color: var(--accent-color);
}

.ant-form-text {
  min-height: 24px;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: var(--accent-color);
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: var(--accent-color);
  box-shadow: 0 0 0 2px var(--accent-color-box-shadow);
}

.ant-switch-checked {
  background: var(--accent-color);
}

.ant-switch-loading, .ant-switch-disabled {
  opacity: 0.6;
}

.ant-tabs-ink-bar {
  background: var(--accent-color)
}

.ant-tabs-tab:hover {
  color: var(--accent-color)
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--accent-color)
}

.ant-tabs-tab-btn:active, .ant-tabs-tab-btn:focus, .ant-tabs-tab-remove:active, .ant-tabs-tab-remove:focus {
  color: var(--accent-color-active)
 }

.rbc-agenda-event-cell {
  cursor: pointer;
}

.rbc-event, .rbc-day-slot .rbc-background-event {
  background-color: var(--accent-color);
  color: var(--text-secondary)
}

.rbc-event.orangeEvent {
  background-color: #e55833;
  color: var(--text-secondary);
}

.rbc-event.orangeEvent.rbc-selected {
  background-color: #d44b29;
  color: var(--text-secondary);
}

.rbc-event.blueEvent {
  background-color: #3da2d4;
  color: var(--text-secondary);
}

.rbc-event.blueEvent.rbc-selected {
  background-color: #308ebb;
  color: var(--text-secondary);
}

.schedule .rbc-event, .rbc-day-slot .rbc-background-event {
  background-color: #ffffff;
  color: var(--text-primary);
}

.rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event {
  border: 1px solid #D0D0D0;
}

.rbc-event.rbc-selected, .rbc-day-slot .rbc-selected.rbc-background-event {
  background-color: var(--accent-color-active);
  color: var(--text-secondary)
}

.site-layout-content {
  text-align: left;
  min-height: 280px;
  max-width: 1700px;
  margin: 0 auto;
}

.site-page-header {
  border-bottom: 1px solid rgb(235, 237, 240);
  padding: 16px 0;
}

button > .svg-inline--fa {
  padding-right: 5px;
}

.transportation-details {
  background-color: #f0f8f8;
  border-radius: 4px;
  border: 0;
  cursor: pointer;
}

.transportation-details .ant-card-body {
  padding: 16px;
}

.transportation-details .ant-descriptions-row > th, .ant-descriptions-row > td {
  padding-bottom: 4px;
}

.transportation-details .ant-descriptions-header {
  margin-bottom: 8px;
}
